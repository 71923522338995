import { Routes, Route } from "react-router-dom";
import Login from "../pages/login/login";
import DefaultLayout from "./../layout/DefaultLayout";
import ContactUs from "./../pages/contactus/contactus";
import Home from "../pages/home/home";

function AppRoutes() {
  // const navigate = useNavigate();

  return (
    <Routes>
      <Route path={`/`} element={<Home />} />
      <Route path={`/home`} element={<Home />} />
      <Route path={`/login`} element={<Login />} />
      <Route path={`/contactus`} element={<ContactUs />} />
      <Route path={`/dashboard/*`} element={<DefaultLayout />} />
    </Routes>
  );
}

export default AppRoutes;
