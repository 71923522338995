import React from "react";

const Dashboard = React.lazy(() =>
  import("../../pages/internal/dashboard/dashboard")
);
const Usuarios = React.lazy(() =>
  import("../../pages/internal/pages/usuarios/Usuarios")
);
const Typography = React.lazy(() =>
  import("../../pages/internal/theme/typography/Typography")
);
const MiniTitulo = React.lazy(() =>
  import("../../pages/internal/pages/miniTitulo/miniTitulo.jsx")
);
const MiniTituloEdit = React.lazy(() =>
  import("../../pages/internal/pages/miniTitulo/miniTituloEdit.jsx")
);

const routes = [
  // { path: "/", exact: true, name: "Home" },
  { path: "/", name: "Dashboard", element: Dashboard },
  { path: "/dashboard/pages/usuarios", name: "Usuários", element: Usuarios },
  {
    path: "dashboard/pages/tipo_de_usuarios",
    name: "Tipo de Usuários",
    element: Typography,
  },
  {
    path: "/dashboard/pages/associados",
    name: "Associados",
    element: Usuarios,
    exact: true,
  },
  { path: "home", name: "Home", element: Usuarios },
  {
    path: "home/mini_titulo",
    name: "Mini Titulo",
    element: MiniTitulo,
    exact: true,
  },
  {
    path: "home/mini_titulo/:id",
    name: "Mini Titulo",
    element: MiniTituloEdit,
    exact: true,
  },
];

export default routes;
